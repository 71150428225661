import queryParams from '@/services/queryParams'
import apiInstance from './index'

export default {
  async getSocieties(paramsObj = {}, filtersObj = {}) {
    const query = queryParams.serializeParams(paramsObj, filtersObj)
    return await apiInstance.get(`societies${query}`)
  },

  async storeSociety(data) {
    return await apiInstance.post('societies', data)
  },

  async getSocietyInfo(id) {
    return await apiInstance.get(`societies/${id}`)
  },

  async updateSociety(data) {
    return await apiInstance.put(`societies/${data.id}`, data)
  },
}

<template>
  <b-overlay
    :show="applyOverlayD"
    :class="applyOverlayD ? 'p-2 mb-1' : 'mb-1'"
  >
    <template #overlay>
      <div class="d-flex align-items-center justify-content-center mt-1">
        <b-spinner
          type="border"
          variant="primary"
        />
      </div>
      <div class="d-flex justify-content-center">
        <p class="pt-1">
          {{ $t('loading') }}
        </p>
      </div>
    </template>
    <b-row v-if="!applyOverlayD">
      <!-- Sociedad -->
      <b-col md="12">
        <b-row>
          <b-col md="6">
            <h5 class="mt-1">
              <strong>{{ $t('clubs.society.societyData') }}</strong>
            </h5>
          </b-col>
          <b-col md="6">
            <span
              v-if="nSocieties > 1 && checkPermission(['DELETE_SOCIETY', 'ALLOW_ALL'])"
              class="float-right mt-1 ml-1"
            >
              {{ $t( 'buttons.delete') }}
              <b-button
                class="btn-icon rounded-circle"
                variant="danger"
                @click="$emit('delete-society', actions.societyId)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </span>
            <span
              v-if="checkPermission(['EDIT_SOCIETY', 'ALLOW_ALL'])"
              class="float-right mt-1"
            >
              {{ $t( 'buttons.edit') }}
              <b-button
                class="btn-icon rounded-circle"
                variant="secondary"
                @click="$emit('edit-society', actions.societyId)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </span>
          </b-col>
        </b-row>
        <hr style="margin-top: 8px;">
      </b-col>
      <b-col
        v-if="societyInfoD.instrument_number"
        md="12"
      >
        <b-list-group>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.society.labels.incorporationDate') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ societyInfoD.incorporation_date | formatDate() }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.instrumentNumber.labels.instrumentNumber') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ societyInfoD.instrument_number.instrument_number }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.instrumentNumber.labels.countryOfNotary') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ societyInfoD.instrument_number.country.labelables[0].label }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.instrumentNumber.labels.stateOfNotary') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ societyInfoD.instrument_number.state.name }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.instrumentNumber.labels.notaryName') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ societyInfoD.instrument_number.notary_name }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.instrumentNumber.labels.notaryNumber') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ societyInfoD.instrument_number.notary_number }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.society.labels.durationSociety') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ societyInfoD.duration }} &nbsp;</span>
                <span>{{ societyInfoD.duration > 1 ? $t('generic.years') : $t('generic.year') }} </span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.society.labels.admissionOfForeigners') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ societyInfoD.apply_foreign_users ? $t('generic.yes') : $t('generic.not') }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.society.labels.corporatePurpose') }}</strong>
              </b-col>
              <b-col md="6">
                <span>{{ societyInfoD.society_object }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.society.labels.wayToManage') }}</strong>
              </b-col>
              <b-col
                v-if="societyInfoD.way_to_manage_hash != null && societyInfoD.way_to_manage_hash != 0"
                md="6"
              >
                <span class="float-left">{{ societyInfoD.way_to_manage.labelables[0].label }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.society.labels.anotherWayToManage') }}</strong>
              </b-col>
              <b-col md="6">
                <span
                  v-if="societyInfoD.other_option != null"
                  class="float-left"
                >
                  {{ societyInfoD.other_option }}
                </span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.society.labels.administrativeStructure') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ societyInfoD.management_structure }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.society.labels.commissioner') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ societyInfoD.commissioner }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('generic.observations') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ societyInfoD.observations }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <!--/ Sociedad -->
      <!-- Socios -->
      <b-col
        md="12"
        class="mt-2"
      >
        <h5>
          <strong>{{ $t('clubs.society.partners') }}</strong>
        </h5>
        <hr>
      </b-col>
      <b-col md="12">
        <b-table
          class="position-relative"
          :no-border-collapse="true"
          responsive
          :items="societyInfoD.partners"
          :fields="partnerFieldsD"
          primary-key="id"
          show-empty
          :empty-text="$t('generic.noRecordsFound')"
        />
      </b-col>
    <!--/ Socios -->
    </b-row>
    <b-row v-else>
      <b-col
        md="12"
        class="i-height-div"
      />
    </b-row>
  </b-overlay>
</template>

<script>
/* eslint-disable object-curly-newline */
import { BRow, BCol, BListGroup, BListGroupItem, BButton, BTable, BBadge, BOverlay } from 'bootstrap-vue'
import AppPaginator from '@/views/components/app-paginator/AppPaginator.vue'
import societyService from '@/services/societyService'

export default {
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BButton,
    BTable,
    BOverlay,
    BBadge,
    AppPaginator,
  },

  props: {
    actions: {
      type: Object,
      default: () => {},
    },
    nSocieties: {
      type: Number,
      default: null,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      societyInfoD: {},
      applyOverlayD: false,
    }
  },

  computed: {
    partnerFieldsD() {
      return [
        {
          key: 'name',
          label: this.$t('clubs.society.labels.partnerName'),
          sortable: true,
        },
        {
          key: 'percentage',
          label: this.$t('clubs.society.labels.shareNumber'),
          sortable: true,
        },
      ]
    },
  },

  created() {
    this.fetchSocietyInfoD()
  },

  methods: {
    fetchSocietyInfoD() {
      this.applyOverlayD = true

      societyService.getSocietyInfo(this.actions.societyId).then(({ data }) => {
        this.societyInfoD = data.data
        this.applyOverlayD = false
      }).catch(error => {
        this.applyOverlayD = false
        this.responseCatch(error)
      })
    },
  },
}
</script>

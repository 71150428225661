<template>
  <!-- crear nueva sociedad -->
  <div v-if="checkPermission(['CREATE_SOCIETY', 'ALLOW_ALL']) && (actions.name == 'create' || actions.name == 'edit')">
    <create-society
      :actions="actions"
      @change-type-view="assignView('list')"
    />
  </div>
  <!--/ crear nueva sociedad -->

  <!-- información de sociedades -->
  <b-row v-else>
    <template v-if="currentSociety">
      <!-- detalle de sociedad -->
      <b-col cols="12">
        <b-overlay
          :show="applyOverlay"
          :class="applyOverlay ? 'p-2 mb-1' : 'mb-1'"
        >
          <template #overlay>
            <div class="d-flex align-items-center justify-content-center mt-1">
              <b-spinner
                type="border"
                variant="primary"
              />
            </div>
            <div class="d-flex justify-content-center">
              <p class="pt-1">
                {{ $t('loading') }}
              </p>
            </div>
          </template>
          <detail-component
            :apply-overlay="applyOverlay"
            :actions="actions"
            :n-societies="paginate.total"
            :check-permission="checkPermission"
            @stop-overlay="applyOverlay = false"
            @edit-society="editSociety"
            @delete-society="deleteSociety"
          />
        </b-overlay>
      </b-col>
      <!--/ detalle de sociedad -->

      <!-- historico de sociedades -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.4)'"
          v-b-toggle.collapse-1
          pill
          block
          variant="outline-primary"
        >
          {{ $t('generic.historicalData') }}
        </b-button>
        <b-collapse
          id="collapse-1"
          class="mt-2"
        >
          <b-row
            v-if="checkPermission(['CREATE_SOCIETY', 'ALLOW_ALL'])"
          >
            <b-col cols="12 mb-1">
              <span class="float-right">
                {{ $t( 'clubs.society.addSociety') }}
                <b-button
                  class="ml-1 btn-icon rounded-circle"
                  variant="primary"
                  :style="colorPrimaryBtn"
                  @click="assignView('create')"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-table
                ref="historyRef"
                hover
                class="position-relative i-min-height-table"
                :items="societies"
                :fields="societyFields"
                :no-border-collapse="true"
                responsive
                primary-key="id"
                show-empty
                :empty-text="$t('generic.noRecordsFound')"
              >
                <template #cell(incorporation_date)="row">
                  <span> {{ row.item.incorporation_date | formatDate() }} </span>
                </template>
                <template #cell(show_details)="row">
                  <b-form-checkbox
                    v-model="row.detailsShowing"
                    class="i-check-hide-co"
                    @change="row.toggleDetails"
                    @input="assignDetailInfo(row.item.hash, row.detailsShowing, row.index)"
                  >
                    <span
                      class="i-text-details-so"
                      :class="{'text-primary' : row.detailsShowing }"
                    >
                      {{ $t('buttons.details') }}
                      <feather-icon
                        icon="ArrowDownIcon"
                        :class="row.detailsShowing ? 'i-icon-arrow-down' : 'i-icon-arrow-up'"
                      />
                    </span>
                  </b-form-checkbox>
                </template>
                <template #row-details="row">
                  <div v-if="actionsTable.showDetail">
                    <detail-in-table
                      :actions="actionsTable"
                      :n-societies="paginate.total"
                      :check-permission="checkPermission"
                      @edit-society="editSociety"
                      @delete-society="deleteSociety"
                    />
                  </div>
                </template>
              </b-table>
              <app-paginator
                :data-list="paginate"
                @pagination-data="changePaginate"
              />
            </b-col>
          </b-row>
        </b-collapse>
      </b-col>
      <!--/ historico de sociedades -->
    </template>

    <!-- Vista principal registro -->
    <b-col
      v-else
      cols="12"
      class="i-min-height-register-so"
    >
      <first-view
        :text-one="$t('clubs.society.registerSociety')"
        :text-two="$t('clubs.society.continueSociety')"
        :text-in-button="$t('clubs.society.newSociety')"
        :color-primary-btn="colorPrimaryBtn"
        @show-register-component="assignView"
      />
    </b-col>
    <!--/ Vista principal registro -->

    <b-col md="12 mt-1">
      <hr>
      <b-button
        class="float-right ml-1"
        variant="secondary"
        pill
        @click="$router.push({ name: 'clubs' })"
      >
        {{ $t('buttons.back') }}
      </b-button>
    </b-col>
  </b-row>
  <!--/ información sociedades -->
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapGetters, mapActions } from 'vuex'
import { BTable, BButton, BCollapse, VBToggle, BOverlay, BFormCheckbox } from 'bootstrap-vue'
import checkPermission from '@/auth/permissions'
import societyService from '@/services/societyService'
import Ripple from 'vue-ripple-directive'
import AppPaginator from '@/views/components/app-paginator/AppPaginator.vue'
import CreateSociety from './Create.vue'
import DetailComponent from './Detail.vue'
import DetailInTable from './DetailInTable.vue'
import FirstView from '../FirstView.vue'

export default {
  components: {
    FirstView,
    CreateSociety,
    DetailComponent,
    DetailInTable,
    AppPaginator,
    BOverlay,
    BTable,
    BButton,
    BCollapse,
    BFormCheckbox,
  },

  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },

  data() {
    return {
      societies: [],
      currentSociety: null,
      applyOverlay: false,
      actions: {
        clubId: null,
        societyId: null,
        name: '',
      },
      actionsTable: {
        clubId: null,
        societyId: null,
        showDetail: false,
      },
      filters: {
        club_hash: null,
        instrument_number_hash: null,
        created_at: null,
        is_current: false,
      },
      paginate: {
        from: 1,
        to: 15,
        total: 0,
      },
    }
  },

  mounted() {
    if (this.$route.params.id === 'undefined' && this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
      this.$router.push({ name: 'clubs' })
      return
    }
    if (this.$route.params.id === 'undefined' && !this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
      this.$router.push({ path: `/clubs/${this.clubInfo.hash}/society` })
    }
    this.filters.club_hash = this.$route.params.id
    this.actions.clubId = this.$route.params.id
    this.actions.name = 'list'

    this.fetchSocieties()
  },

  computed: {
    ...mapGetters({ colorPrimaryBtn: 'colorPrimaryBtn', clubInfo: 'clubInfo' }),

    societyFields() {
      return [
        {
          key: 'club.denomination',
          label: this.$t('clubs.corporate.denominationOrTradeName'),
          sortable: true,
        },
        {
          key: 'instrument_number.instrument_number',
          label: this.$t('clubs.instrumentNumber.labels.instrumentNumber'),
          sortable: true,
        },
        {
          key: 'incorporation_date',
          label: this.$t('clubs.society.labels.incorporationDate'),
          sortable: true,
        },
        {
          key: 'show_details',
          label: '',
          sortable: false,
        },
      ]
    },
  },

  methods: {
    checkPermission,
    ...mapActions(['deleteSocietyByClubId']),

    assignView(pTypeView) {
      this.actions.societyId = null
      this.actions.name = pTypeView

      if (pTypeView == 'list') {
        this.fetchSocieties()
        this.returnTop()
      }
    },

    fetchSocieties() {
      this.applyOverlay = true

      const params = {
        included: 'club,instrumentNumber,wayToManage,partners',
        perPage: this.paginate.to,
        page: this.paginate.from,
      }

      societyService.getSocieties(params, this.filters).then(({ data }) => {
        this.paginate.total = data.total

        if (data.total > 0) {
          this.currentSociety = data.data.filter(item => item.is_current)
          this.societies = data.data.filter(item => !item.is_current)

          setTimeout(() => { this.actions.societyId = this.currentSociety[0].hash }, 300)
        } else this.applyOverlay = false
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    editSociety(pSociety) {
      this.actions.societyId = pSociety
      this.actions.name = 'edit'
    },

    deleteSociety(pSocietyIdX) {
      const societyParamX = {
        clubId: this.$route.params.id,
        societyId: pSocietyIdX,
      }

      this.deleteSocietyByClubId(societyParamX)
    },

    changePaginate(paginateData) {
      this.paginate.to = paginateData.perPage
      this.paginate.from = paginateData.page
      this.fetchSocieties()
    },

    assignDetailInfo(pSocietyId, pCheckboxValue, pIndex) {
      if (pCheckboxValue) {
        this.actionsTable.showDetail = false

        for (let i = 0; i <= this.$refs.historyRef.localItems.length - 1; i++) {
          if (i != pIndex) {
            if (this.$refs.historyRef.localItems[i]._showDetails != undefined) {
              this.$refs.historyRef.localItems[i]._showDetails = false
            }
          }
        }

        this.actionsTable.showDetail = true
        this.actionsTable.clubId = parseInt(this.$route.params.id, 10)
        this.actionsTable.societyId = pSocietyId
      }
    },

    returnTop() {
      const rootEle = document.documentElement

      rootEle.scrollTo({
        top: 50,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style scoped>
.i-text-details-so {
  font-size: 12px;
  color: #33334d;
  visibility: visible;
  cursor: pointer;
}

.i-text-details-so:hover {
  color: #6abeff;
}

.i-min-height-register-so {
  min-height:300px;
}

.i-check-hide-co {
  visibility: hidden;
}

.i-icon-arrow-up {
  padding-bottom: 2px;
}

.i-icon-arrow-down {
  padding-top: 2px;
  transform: rotate(180deg);
}
</style>

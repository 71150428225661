<template>
  <b-row v-if="!applyOverlay">
    <!-- Sociedad -->
    <b-col md="12">
      <b-row>
        <b-col md="6">
          <h5 class="mt-1">
            <strong>{{ $t('clubs.society.societyData') }}</strong>
          </h5>
        </b-col>
        <b-col md="6">
          <span
            v-if="nSocieties > 1 && checkPermission(['DELETE_SOCIETY', 'ALLOW_ALL'])"
            class="float-right mt-1 ml-1"
          >
            {{ $t( 'buttons.delete') }}
            <b-button
              class="btn-icon rounded-circle"
              variant="danger"
              @click="$emit('delete-society', actions.societyId)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </span>
          <span
            v-if="checkPermission(['EDIT_SOCIETY', 'ALLOW_ALL'])"
            class="float-right mt-1"
          >
            {{ $t( 'buttons.edit') }}
            <b-button
              class="btn-icon rounded-circle"
              variant="secondary"
              @click="$emit('edit-society', actions.societyId)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </span>
        </b-col>
      </b-row>
      <hr style="margin-top: 8px;">
    </b-col>
    <b-col md="12">
      <b-list-group>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.society.labels.incorporationDate') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ societyInfo.incorporation_date | formatDate }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.instrumentNumber.labels.instrumentNumber') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ societyInfo.instrument_number.instrument_number }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.instrumentNumber.labels.countryOfNotary') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ societyInfo.instrument_number.country.labelables[0].label }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.instrumentNumber.labels.stateOfNotary') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ societyInfo.instrument_number.state.name }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.instrumentNumber.labels.notaryName') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ societyInfo.instrument_number.notary_name }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.instrumentNumber.labels.notaryNumber') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ societyInfo.instrument_number.notary_number }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.society.labels.durationSociety') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ societyInfo.duration }} &nbsp;</span>
              <span>{{ societyInfo.duration > 1 ? $t('generic.years') : $t('generic.year') }} </span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.society.labels.admissionOfForeigners') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ societyInfo.apply_foreign_users ? $t('generic.yes') : $t('generic.not') }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.society.labels.corporatePurpose') }}</strong>
            </b-col>
            <b-col md="6">
              <span>{{ societyInfo.society_object }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.society.labels.wayToManage') }}</strong>
            </b-col>
            <b-col
              v-if="societyInfo.way_to_manage_hash != null && societyInfo.way_to_manage_hash != 0"
              md="6"
            >
              <span class="float-left">{{ societyInfo.way_to_manage.labelables[0].label }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.society.labels.anotherWayToManage') }}</strong>
            </b-col>
            <b-col md="6">
              <span
                v-if="societyInfo.other_option != null"
                class="float-left"
              >
                {{ societyInfo.other_option }}
              </span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.society.labels.administrativeStructure') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ societyInfo.management_structure }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.society.labels.commissioner') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ societyInfo.commissioner }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('generic.observations') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ societyInfo.observations }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-col>
    <!--/ Sociedad -->
    <!-- Socios -->
    <b-col
      md="12"
      class="mt-2"
    >
      <h5>
        <strong>{{ $t('clubs.society.partners') }}</strong>
      </h5>
      <hr>
    </b-col>
    <b-col md="12">
      <b-table
        class="position-relative"
        :no-border-collapse="true"
        responsive
        :items="societyInfo.partners"
        :fields="partnerFields"
        primary-key="id"
        show-empty
        :empty-text="$t('generic.noRecordsFound')"
      />
    </b-col>
    <!--/ Socios -->
  </b-row>
  <b-row v-else>
    <b-col
      md="12"
      class="i-height-div"
    />
  </b-row>
</template>

<script>
/* eslint-disable object-curly-newline */
import { BListGroup, BListGroupItem, BButton, BTable, BBadge } from 'bootstrap-vue'
import societyService from '@/services/societyService'
import AppPaginator from '@/views/components/app-paginator/AppPaginator.vue'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BButton,
    BTable,
    BBadge,
    AppPaginator,
  },

  props: {
    applyOverlay: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Object,
      default: () => {},
    },
    nSocieties: {
      type: Number,
      default: null,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      societyInfo: {},
    }
  },

  watch: {
    'actions.societyId': function () {
      this.fetchSocietyInfo()
    },
  },

  computed: {
    partnerFields() {
      return [
        {
          key: 'name',
          label: this.$t('clubs.society.labels.partnerName'),
          sortable: true,
        },
        {
          key: 'percentage',
          label: this.$t('clubs.society.labels.shareNumber'),
          sortable: true,
        },
      ]
    },
  },

  methods: {
    formatDate,
    fetchSocietyInfo() {
      societyService.getSocietyInfo(this.actions.societyId).then(({ data }) => {
        this.societyInfo = data.data
        this.$emit('stop-overlay')
      }).catch(error => {
        this.$emit('stop-overlay')
        this.responseCatch(error)
      })
    },
  },
}
</script>

<template>
  <b-row>
    <b-col cols="12">
      <h5>
        <strong>{{ $t('clubs.society.societyRegistration') }}</strong>
      </h5>
      <span class="i-text-request-info">
        {{ $t('corporate.create.textRequest') }}
      </span>
      <hr>
    </b-col>
    <!-- form -->
    <b-col md="12">
      <b-overlay
        :show="applyOverlay"
        :class="applyOverlay ? 'p-2 mb-1' : 'mb-1'"
        opacity="1"
      >
        <template #overlay>
          <div class="d-flex align-items-center justify-content-center mt-1">
            <b-spinner
              type="border"
              variant="primary"
            />
          </div>
          <div class="d-flex justify-content-center">
            <p class="pt-1">
              {{ $t('loading') }}
            </p>
          </div>
        </template>
        <b-row>
          <b-col
            v-if="!applyOverlay"
            md="12"
          >
            <validation-observer ref="societyRules">
              <b-form @submit.prevent="updatingData = true">
                <b-row>
                  <b-col md="6">
                    <b-form-group for="society-incorporation-date">
                      {{ $t('clubs.society.labels.incorporationDate') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="incorporationDate"
                        rules="required"
                      >
                        <b-form-datepicker
                          id="society-incorporation-date"
                          v-model="society.incorporationDate"
                          hide-header
                          :locale="$i18n.locale"
                          :placeholder="$t('clubs.society.placeholder.selectIncorporationDate')"
                          :state="errors.length > 0 ? false : null"
                          :max="todaySoc"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="society-instrument-number">
                      {{ $t('clubs.instrumentNumber.labels.instrumentNumber') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="instrumentNumber"
                        rules="required|integer|min:1|max:20"
                      >
                        <b-form-input
                          id="society-instrument-number"
                          v-model="society.instrument.instrumentNumber"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('clubs.instrumentNumber.placeholder.enterInstrument')"
                          :disabled="clubInfo.societies !== undefined && clubInfo.societies.length > 0 && $route.name == 'admin-create-club'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="society-country-notary">
                      {{ $t('clubs.instrumentNumber.labels.countryOfNotary') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="countryOfNotary"
                        rules="required"
                      >
                        <v-select
                          id="society-country-notary"
                          v-model="society.instrument.countryOfNotaryId"
                          :state="errors.length > 0 ? false : null"
                          label="name"
                          :reduce="option => option.id"
                          :options="countries"
                          :placeholder="$t('generic.selectCountry')"
                          @input="fetchStates(society.instrument.countryOfNotaryId)"
                        >
                          <div slot="no-options">
                            {{ $t('generic.noRecordsFound') }}
                          </div>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="society-state-notary">
                      {{ $t('clubs.instrumentNumber.labels.stateOfNotary') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="stateOfNotary"
                        rules="required"
                      >
                        <v-select
                          id="society-state-notary"
                          v-model="society.instrument.stateOfNotaryId"
                          :state="errors.length > 0 ? false : null"
                          :reduce="option => option.id"
                          label="name"
                          :options="states"
                          :placeholder="$t('generic.selectState')"
                          :disabled="society.instrument.countryOfNotaryId == null"
                        >
                          <div slot="no-options">
                            {{ $t('generic.noRecordsFound') }}
                          </div>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="society-notary-name">
                      {{ $t('clubs.instrumentNumber.labels.notaryName') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="notaryName"
                        rules="required|max:255|nameRegex"
                      >
                        <b-form-input
                          id="society-notary-name"
                          v-model="society.instrument.notaryName"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('clubs.instrumentNumber.placeholder.selectNotaryName')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="society-notary-number">
                      {{ $t('clubs.instrumentNumber.labels.notaryNumber') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="notaryNumber"
                        rules="required|integer|min:1|max:12"
                      >
                        <b-form-input
                          id="society-notary-number"
                          v-model="society.instrument.notaryNumber"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('clubs.instrumentNumber.placeholder.selectNotaryNumber')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                    md="4"
                    lg="3"
                  >
                    <b-form-group label-for="society-duration">
                      {{ $t('clubs.society.labels.durationSociety') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="durationSociety"
                        rules="required|integer|between:1,100"
                      >
                        <b-input-group>
                          <b-form-input
                            id="society-duration"
                            v-model="society.duration"
                            class="form-control"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('clubs.society.placeholder.enterDurationSociety')"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                    md="4"
                    lg="3"
                  >
                    <label
                      label-for="society-apply"
                      class="i-font-size-date"
                    >
                      {{ $t('clubs.society.labels.admissionOfForeigners') }} <br>
                      <b-row>
                        <b-col md="4">
                          <b-form-checkbox
                            v-model="society.applyForeignUsers"
                            checked="true"
                            name="check-button"
                            switch
                            inline
                          >
                            <span>{{ society.applyForeignUsers ? $t('generic.yes') : $t('generic.not') }}</span>
                          </b-form-checkbox>
                        </b-col>
                      </b-row>
                    </label>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <rich-text-edit-ass
                      :text-one="$t('clubs.society.labels.corporatePurpose')"
                      :text-two="'corporatePurpose'"
                      :agreements="society.societyObject"
                      :updating-data="updatingData"
                      :apply-edition-x="actions.name"
                      :read-only-text="false"
                      @assign-text-edit="validateSocietyData"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="12"
                    class="mt-2"
                  >
                    <h5 class="mb-0">
                      {{ $t('clubs.society.labels.shareDistribution') }}
                    </h5>
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col md="6">
                    <b-form-group label-for="society-options-m">
                      {{ $t('clubs.society.labels.wayToManage') }}
                      <span
                        v-if="!applyOther"
                        class="text-danger"
                      >
                        *
                      </span>
                      <validation-provider
                        #default="{ errors }"
                        name="wayToManage"
                        :rules="applyOther ? '' : 'required'"
                      >
                        <v-select
                          id="society-options-m"
                          v-model="society.wayToManageId"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :state="errors.length > 0 ? false : null"
                          :options="waysToManage"
                          :reduce="option => option.id"
                          :placeholder="$t('clubs.society.placeholder.selectWayToManage')"
                          label="name"
                          :disabled="applyOther"
                        >
                          <div slot="no-options">
                            {{ $t('generic.noRecordsFound') }}
                          </div>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                  >
                    <b-button
                      class="mt-2"
                      :variant="applyOther ? 'secondary' : 'primary'"
                      :style="colorPrimaryBtn"
                      pill
                      @click="applyOther = !applyOther"
                    >
                      <span v-if="applyOther">{{ $t('buttons.cancel') }}</span>
                      <span v-else>{{ $t('clubs.society.placeholder.addAnotherWay') }}</span>
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="applyOther"
                    md="6"
                  >
                    <b-form-group label-for="society-other-option">
                      {{ $t('clubs.society.labels.anotherWayToManage') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="anotherWayToManage"
                        rules="required|min:1|max:255|nameRegex"
                      >
                        <b-input-group>
                          <b-form-input
                            id="society-other-option"
                            v-model="society.otherOption"
                            :state="errors.length > 0 ? false : null"
                            class="form-control"
                            :placeholder="$t('clubs.society.placeholder.enterAnotherWay')"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label-for="society-management-structure">
                      {{ $t('clubs.society.labels.administrativeStructure') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="administrativeStructure"
                        rules="required|min:5|max:255|clubNameRegex"
                      >
                        <b-form-textarea
                          id="society-management-structure"
                          v-model="society.managementStructure"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('clubs.society.placeholder.enterStructure')"
                          rows="2"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="society-commissioner">
                      {{ $t('clubs.society.labels.commissioner') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="commissioner"
                        rules="required|min:5|max:255|nameRegex"
                      >
                        <b-form-input
                          id="society-commissioner"
                          v-model="society.commissioner"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('clubs.society.placeholder.enterCommissioner')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label-for="society-observations">
                      {{ $t('generic.observations') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="observations"
                        rules="required|min:5|max:255|clubNameRegex"
                      >
                        <b-form-textarea
                          id="society-observations"
                          v-model="society.observations"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('generic.enterObservations')"
                          rows="2"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                    md="4"
                    lg="3"
                  >
                    <label
                      label-for="society-current"
                      class="i-font-size-date"
                    >
                      {{ $t('generic.markAsCurrent') }} <br>
                      <b-row>
                        <b-col md="4">
                          <b-form-checkbox
                            v-model="society.isCurrent"
                            checked="true"
                            name="check-button"
                            switch
                            inline
                          >
                            <span>{{ society.isCurrent ? $t('generic.yes') : $t('generic.not') }}</span>
                          </b-form-checkbox>
                        </b-col>
                      </b-row>
                    </label>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12 my-1">
                    <span class="float-right">
                      {{ $t( 'clubs.society.addPartner') }}
                      <b-button
                        class="ml-1 btn-icon rounded-circle"
                        variant="primary"
                        :style="colorPrimaryBtn"
                        @click="addPartnerForm"
                      >
                        <feather-icon icon="PlusIcon" />
                      </b-button>
                    </span>
                  </b-col>
                  <b-col md="12 mt-2">
                    <b-table
                      class="position-relative"
                      responsive
                      :no-border-collapse="true"
                      :items="society.partners"
                      :fields="fields"
                      primary-key="id"
                      show-empty
                      :empty-text="$t('generic.noRecordsFound')"
                    >
                      <template #cell(actions)="data">
                        <span
                          class="btn-cursor"
                          @click="editPartner(data.item.id)"
                        >
                          {{ $t('Details') }}
                        </span>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
                <b-row v-if="checkPermission(['ALLOW_ALL'])">
                  <b-col md="12">
                    <hr>
                    <b-button
                      class="float-right ml-1"
                      variant="primary"
                      pill
                      type="submit"
                      :style="colorPrimaryBtn"
                    >
                      {{ $t( 'buttons.save') }}
                    </b-button>
                    <b-button
                      class="float-right"
                      variant="secondary"
                      pill
                      @click="$emit('change-type-view')"
                    >
                      {{ $t('buttons.cancel') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-col>
          <b-col
            v-else
            cols="12"
            class="i-height-div"
          />
        </b-row>
      </b-overlay>
    </b-col>
    <!--/ form -->
    <!-- modal -->
    <b-modal
      id="modal-partner"
      ref="modalPartner"
      :title="applyEdition ? $t('clubs.society.labels.updatePartner') : $t('clubs.society.labels.newPartner')"
      centered
      no-close-on-backdrop
      @close="closeModal(false)"
    >
      <validation-observer ref="partnerRules">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group label-for="partner-name">
                {{ $t('clubs.society.labels.partnerName') }}
                <span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="partnerName"
                  rules="required|min:1|max:255|nameRegex"
                >
                  <b-form-input
                    id="partner-name"
                    v-model="partnerForm.name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('clubs.society.placeholder.enterPartnerName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-for="partner-percentage">
                {{ $t('clubs.society.labels.shareNumber') }}
                <span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="shareNumber"
                  rules="required|integer|between:1,100"
                >
                  <b-input-group>
                    <b-form-input
                      id="partner-percentage"
                      v-model.number="partnerForm.percentage"
                      class="form-control"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('clubs.society.placeholder.enterPercentage')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <div class="float-right">
          <b-button
            v-if="applyEdition"
            pill
            class="mr-1"
            variant="outline-secondary"
            @click="deletePartner()"
          >
            <i class="icon-0-icons-dark-trash-can mr-25" />
            {{ $t('buttons.delete') }}
          </b-button>

          <b-button
            pill
            variant="secondary"
            @click="closeModal(true)"
          >
            {{ $t('buttons.cancel') }}
          </b-button>

          <b-button
            pill
            variant="primary"
            style="margin-left: 17px;"
            :style="colorPrimaryBtn"
            @click="validatePartnerForm"
          >
            {{ $t('buttons.save') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <!--/ modal -->
  </b-row>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer, min, max, nameRegex, between, clubNameRegex } from '@validations'
import { BModal, BFormGroup, BForm, BFormInput, BInputGroup, BFormDatepicker, BFormTextarea, BTable, BButton, BFormCheckbox } from 'bootstrap-vue'
import checkPermission from '@/auth/permissions'
import { waysToManage } from '@/services/catalogsService'
import countryService from '@/services/countryService'
import societyService from '@/services/societyService'
import clubService from '@/services/clubsService'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import RichTextEditAss from '@/views/partials/editors/RichTextEdit.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormGroup,
    BForm,
    BFormInput,
    BInputGroup,
    BFormTextarea,
    BTable,
    BButton,
    BFormCheckbox,
    BFormDatepicker,
    vSelect,
    RichTextEditAss,
  },

  directives: {
    Ripple,
  },

  props: {
    actions: {
      type: Object,
      default: () => {},
    },
    oldActive: {
      type: Number,
      default: 0,
    },
    paramsSociety: {
      type: Object,
      default: () => {},
    },
    stepNumber: {
      type: Number,
      default: null,
    },
    stepPreviousNumber: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      todaySoc: new Date(),
      statesList: [],
      countriesList: [],
      wayToManageList: [],
      applyOther: false,
      applyEdition: false,
      applyOverlay: false,
      updatingData: false,
      indexEdit: null,
      partnerForm: {
        id: null,
        name: '',
        percentage: 0,
      },
      backDataPartner: {
        id: null,
        name: '',
        percentage: '',
      },
      society: {
        id: null,
        clubId: null,
        incorporationDate: null,
        // instrumentNumberId: null,
        instrument: {
          id: null,
          instrumentNumber: null,
          countryOfNotaryId: null,
          stateOfNotaryId: null,
          notaryName: null,
          notaryNumber: null,
          clubId: null,
        },
        duration: 1,
        applyForeignUsers: false,
        societyObject: '',
        wayToManageId: null,
        otherOption: '',
        managementStructure: '',
        observations: '',
        commissioner: '',
        partners: [],
        status: 1,
        isCurrent: true,
        counter: 1, // se utiliza como id al agregar partners (socios)
      },
      required, // validation
      integer, // validation
      min, // validation
      max, // validation
      nameRegex, // validation
      clubNameRegex, // validation
      between, // validation
    }
  },

  computed: {
    ...mapGetters({
      clubInfo: 'clubInfo',
      colorPrimaryBtn: 'colorPrimaryBtn',
    }),

    countries() {
      const list = this.countriesList.map(c => ({ id: c.hash, name: c.labelables[0].label }))
      return list
    },

    states() {
      const list = this.statesList.map(c => ({ id: c.hash, name: c.name }))
      return list
    },

    waysToManage() {
      const list = this.wayToManageList.map(c => ({ id: c.hash, name: c.name }))
      return list
    },

    fields() {
      return [
        {
          key: 'name',
          label: this.$t('clubs.society.labels.partnerName'),
          sortable: true,
        },
        {
          key: 'percentage',
          label: this.$t('clubs.society.labels.shareNumber'),
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ]
    },
  },

  watch: {
    '$i18n.locale': function () {
      this.fetchCountries()
      this.fetchWays()
    },

    'society.instrument.countryOfNotaryId': function (item, old) {
      if (item != null) {
        if (item != old && old != null && old !== '') {
          this.society.instrument.stateOfNotaryId = null
          this.statesList = []
        }
        this.fetchStates(item)
      } else {
        this.society.instrument.stateOfNotaryId = null
        this.statesList = []
      }
    },

    applyOther(value) {
      (value) ? this.society.wayToManageId = null : this.society.otherOption = ''
    },

    stepNumber(pCurrent) {
      if (pCurrent == 2) { // paso 2 wizard
        this.$refs.societyRules.validate().then(success => {
          if (success) { // paso 2 wizard listo para avanzar a paso 3
            if (this.society.partners.length > 1) {
              const formSociety = this.assignDataSteps()
              this.$emit('save-data-step-two', formSociety, true, null)
            } else {
              this.$emit('save-data-step-two', null, false, null)
              this.showMinimumPartnerNumber()
            }
          } else {
            this.$emit('save-data-step-two', null, false, null)
          }
        })
      }
    },

    stepPreviousNumber(sCurrent) {
      if (sCurrent == 1) {
        const formStepsX = this.assignDataSteps()
        this.$emit('save-data-prev-two', formStepsX)
      }
    },
  },

  mounted() {
    this.fetchCountries()
    this.assignSociety()
  },

  methods: {
    checkPermission,
    ...mapActions([
      'fetchSocietyInfo',
      'saveSociety',
      'updateSociety',
    ]),

    fetchCountries() {
      countryService.getCountries().then(({ data }) => {
        this.countriesList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    fetchStates(id) {
      const filtersObj = { country_hash: id }

      countryService.getStates(filtersObj).then(({ data }) => {
        this.statesList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    fetchWays() {
      waysToManage().then(({ data }) => {
        this.wayToManageList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    assignDataSteps() {
      const dataStep = {
        id: this.society.id,
        club_id: this.clubInfo.hash,
        incorporation_date: this.society.incorporationDate,
        instrument_number_id: this.society.instrument.id,
        instrument: {
          id: this.society.instrument.id,
          instrument_number: this.society.instrument.instrumentNumber,
          country_of_notary_id: this.society.instrument.countryOfNotaryId,
          state_of_notary_id: this.society.instrument.stateOfNotaryId,
          notary_name: this.society.instrument.notaryName,
          notary_number: this.society.instrument.notaryNumber,
          club_id: this.society.clubId,
        },
        society_object: this.society.societyObject,
        duration: this.society.duration,
        apply_foreign_users: this.society.applyForeignUsers,
        way_to_manage_id: this.society.wayToManageId,
        other_option: this.society.otherOption,
        management_structure: this.society.managementStructure,
        observations: this.society.observations,
        commissioner: this.society.commissioner,
        partners: this.society.partners,
        status: this.society.status,
        is_current: this.society.isCurrent,
        counter: this.society.counter, // se asigna al objeto como referencia en (wizard)
      }

      return dataStep
    },

    assignSociety() {
      this.fetchWays()

      if (this.actions.clubId == null && this.actions.societyId == null) { // crear sociedad (flujo normal y wizard)
        if (this.oldActive == 3 || (this.oldActive == 1 && this.paramsSociety != null)) { // aplica solo en wizard cuando se da marcha atras de paso 3 a 2
          this.assignCurrentSocietyData(this.paramsSociety, true)
        } else if (this.clubInfo.societies !== undefined && this.clubInfo.societies.length) { // aplica solo en wizard al tener sociedades registradas pero no apoderados
          this.fetchCurrentSocietyByClubId()
        }
      } else if (this.actions.name == 'edit') { // aplica en flujo normal (edición)
        this.applyOverlay = true
        societyService.getSocietyInfo(this.actions.societyId).then(({ data }) => {
          const pSocietyData = data.data
          this.assignCurrentSocietyData(pSocietyData, false)
          this.applyOverlay = false
        }).catch(error => {
          this.applyOverlay = false
          this.responseCatch(error)
        })
      }
    },

    fetchCurrentSocietyByClubId() {
      this.applyOverlay = true
      clubService.getCurrentSocietyByClubId(this.clubInfo.hash).then(({ data }) => {
        const pSocietyData = data.data
        this.assignCurrentSocietyData(pSocietyData, false)
        this.applyOverlay = false
      }).catch(error => {
        this.applyOverlay = false
        this.responseCatch(error)
      })
    },

    assignCurrentSocietyData(societyData, applyCounter) {
      this.society = {
        id: applyCounter ? societyData.id : societyData.hash,
        clubId: societyData.club_id,
        incorporationDate: societyData.incorporation_date,
        instrumentNumberId: societyData.instrument_number_id,
        instrument: {
          id: applyCounter ? societyData.instrument.id : societyData.instrument_number.hash,
          instrumentNumber: applyCounter ? societyData.instrument.instrument_number : societyData.instrument_number.instrument_number,
          countryOfNotaryId: applyCounter ? societyData.instrument.country_of_notary_id : societyData.instrument_number.country_of_notary_hash,
          stateOfNotaryId: applyCounter ? societyData.instrument.state_of_notary_id : societyData.instrument_number.state_of_notary_hash,
          notaryName: applyCounter ? societyData.instrument.notary_name : societyData.instrument_number.notary_name,
          notaryNumber: applyCounter ? societyData.instrument.notary_number : societyData.instrument_number.notary_number,
          clubId: applyCounter ? societyData.instrument.club_id : societyData.instrument_number.club_id,
        },
        societyObject: societyData.society_object,
        duration: societyData.duration,
        applyForeignUsers: societyData.apply_foreign_users,
        wayToManageId: applyCounter ? societyData.way_to_manage_id : societyData.way_to_manage_hash,
        otherOption: societyData.other_option,
        managementStructure: societyData.management_structure,
        observations: societyData.observations,
        commissioner: societyData.commissioner,
        partners: applyCounter ? societyData.partners.map(c => ({ id: c.id, name: c.name, percentage: c.percentage })) : societyData.partners.map(c => ({ id: c.hash, name: c.name, percentage: c.percentage })),
        status: societyData.status,
        isCurrent: societyData.is_current,
        counter: applyCounter ? societyData.counter : 1,
      }

      this.applyOther = (this.society.wayToManageId == null || this.society.wayToManageId == 0)
    },

    addPartnerForm() {
      this.resetPartnerForm()
      this.applyEdition = false
      this.$bvModal.show('modal-partner')
    },

    resetPartnerForm() {
      this.indexEdit = null
      this.backDataPartner = {
        id: null,
        name: '',
        percentage: '',
      }
      this.partnerForm = {
        id: null,
        name: '',
        percentage: '',
      }
    },

    closeModal(flagM = false) {
      if (flagM) this.$bvModal.hide('modal-partner')

      this.resetPartnerForm()
    },

    editPartner(pIndexP) {
      this.resetPartnerForm()
      this.indexEdit = this.society.partners.findIndex(el => el.id == pIndexP)
      this.backDataPartner = JSON.parse(JSON.stringify(this.society.partners[this.indexEdit]))
      this.partnerForm = JSON.parse(JSON.stringify(this.society.partners[this.indexEdit]))
      this.applyEdition = true
      this.$bvModal.show('modal-partner')
    },

    deletePartner() {
      this.society.partners.splice(this.indexEdit, 1)
      this.closeModal(true)
    },

    validatePartnerForm() {
      this.$refs.partnerRules.validate().then(success => {
        if (success && this.applyEdition) {
          if (this.validateTotalActions(this.partnerForm, true)) {
            if (this.validateNamePartner(this.partnerForm.name.trim())) {
              this.partnerForm.name = this.partnerForm.name.trim()
              this.society.partners.splice(this.indexEdit, 1, this.partnerForm)
              this.$bvModal.hide('modal-partner')
            } else this.showErrorInPartner('El nombre ya existe')
          } else {
            this.society.partners.splice(this.indexEdit, 1, this.backDataPartner)
          }
        } else if (success && this.validateTotalActions(this.partnerForm, false)) {
          if (this.validateNamePartner(this.partnerForm.name.trim())) {
            this.partnerForm.id = this.society.counter
            this.partnerForm.name = this.partnerForm.name.trim()
            this.society.partners.push(this.partnerForm)
            this.society.counter += 1
            this.$bvModal.hide('modal-partner')
            this.resetPartnerForm()
          } else this.showErrorInPartner('El nombre ya existe')
        }
      })
    },

    validateTotalActions(pForm, isEdit) {
      let actionsNumber = 0

      if (pForm.percentage < 1) {
        this.showErrorInPartner('El número de acciones por sociedad no puede ser menor a 1')
        return false
      }

      if (isEdit) {
        if (this.indexEdit !== -1) {
          const backupCurrentPartners = JSON.parse(JSON.stringify(this.society.partners))
          backupCurrentPartners.splice(this.indexEdit, 1, pForm)

          actionsNumber = backupCurrentPartners.reduce((previousValue, currentValue) => previousValue + Number(currentValue.percentage), 0)

          if (actionsNumber > 100) {
            this.showErrorInPartner('El número de acciones por sociedad no puede ser mayor a 100')
            return false
          }
          return true
        }
        return false
      }

      if (this.society.partners.length) {
        actionsNumber = this.society.partners.reduce((previousValue, currentValue) => previousValue + Number(currentValue.percentage), 0)
      }

      if (actionsNumber + Number(pForm.percentage) > 100) {
        this.showErrorInPartner('El número de acciones por sociedad no puede ser mayor a 100')
        return false
      }
      return true
    },

    validateNamePartner(params) {
      let resultName = this.society.partners.findIndex(el => el.name.toLowerCase() == params.toLowerCase())
      if (resultName == this.indexEdit) {
        resultName = -1
      }
      return (resultName === -1)
    },

    validateSocietyData(pText, successRule) {
      this.society.societyObject = pText

      this.$refs.societyRules.validate().then(success => {
        if (success && successRule) {
          if (this.society.partners.length > 1) {
            const societyDataX = {
              id: null,
              club_id: parseInt(this.actions.clubId, 10),
              incorporation_date: this.society.incorporationDate,
              instrument_number_id: null,
              instrument_id: this.society.instrument.id,
              instrument_number: this.society.instrument.instrumentNumber,
              country_of_notary_id: this.society.instrument.countryOfNotaryId,
              state_of_notary_id: this.society.instrument.stateOfNotaryId,
              notary_name: this.society.instrument.notaryName,
              notary_number: this.society.instrument.notaryNumber,
              society_object: this.society.societyObject,
              duration: this.society.duration,
              apply_foreign_users: this.society.applyForeignUsers,
              way_to_manage_id: this.society.wayToManageId,
              other_option: this.society.otherOption,
              management_structure: this.society.managementStructure,
              observations: this.society.observations,
              commissioner: this.society.commissioner,
              partners: this.society.partners,
              status: this.society.status,
              is_current: this.society.isCurrent,
            }

            if (this.actions.name == 'edit') {
              societyDataX.id = this.actions.societyId
              societyDataX.instrument_number_id = this.society.instrumentNumberId

              societyService.updateSociety(societyDataX).then(({ data }) => {
                this.responseSuccessUpdate(data.message)
                this.$emit('change-type-view')
              }).catch(error => {
                this.responseCatch(error)
              })
            } else {
              societyService.storeSociety(societyDataX).then(({ data }) => {
                this.responseSuccessCreate(data.message)
                this.$emit('change-type-view')
              }).catch(error => {
                this.responseCatch(error)
              })
            }
          } else {
            this.showMinimumPartnerNumber()
          }
        } else {
          this.updatingData = false
        }
      })
    },

    showMinimumPartnerNumber() {
      this.$swal({
        title: '¡Atención!',
        text: 'Debes registrar almenos dos socios, para poder continuar.',
        icon: 'warning',
        timer: 2000,
        showConfirmButton: true,
        confirmButtonText: `${this.$t('generic.accept')}`,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    showErrorInPartner(pMessage) {
      this.$swal({
        title: '¡Atención!',
        text: pMessage,
        icon: 'warning',
        timer: 3500,
        showConfirmButton: true,
        confirmButtonText: `${this.$t('generic.accept')}`,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style scoped>
.btn-cursor {
  cursor: pointer;
}
.btn-cursor:hover {
  color: #6abeff;
}
</style>
